export const futureShowDict = {
  'rivers-end-pub-11.2.24': {
    id: 'rivers-end-pub-11.2.24',
    imgSrc: 'rivers-end-pub-11.2.24.png',
    date: "11/02/2024",
    shortDate: "11/2/24",
    name: "River's End Pub",
    liveAt: `
        <span>Super OK live at</span>
        <a target="_blank" href="https://www.instagram.com/riversendpub/">River's End Pub</a>`,
    /*
    timeDetails: [
      // `<span>Doors - 8:00pm</span>`,
      `<span>Limit State</span>`,
      `<span><b>Super OK</b></span>`,
      `<span>Strictly Elizabeth</span>`,
      `<span>Aster Baby and the Wild Rabbits</span>`,
    ],
    */
    address: `13130 Soledad Canyon Rd, Santa Clarita, CA 91390`,
    shortLocation: 'Santa Clarita, CA',
    addressLink: `https://maps.app.goo.gl/vcmN48ovawMVsFg79`,
  },
};

export const futureShowList = [
  futureShowDict['rivers-end-pub-11.2.24'],
];
